import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';

function Smashrunrunslist () {

		const[runs, setRuns]= useState([]);
		const[summary, setSummary] = useState([]);
    			
  		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";	
		
		const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
	
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getSmashrunData(timeFrame);
			getSummaryData(timeFrame);	
        },[timeFrame])  
        
        const getSmashrunData = (timeFrame) => {

			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'smashrunrunslist',
				'TIMEFRAME': `${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setRuns( data ))
     	};  
     	
     	const getSummaryData = (timeFrame) => {
        	fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'smashrunrunslist_summary',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setSummary( data ))
        };
  		
    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Runs</title>
			</Helmet>
       		<div id = "page-wrap">
       		<div id="logo" className="sitelogo"><img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		<div className="container-fluid">
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
        		
        			<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex align-items-center justify-content-center">
								<table className="table table-bordered table-striped table-sm">
									<thead className="thead-inverse">
									<tr>
										<td className="text-center" colSpan="6">
												<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
												<h4>Runs Overview - Last {timeFrame} Days</h4>
											
										</td>
									</tr>	
									<tr>
										<th className="text-center">Avg Duration</th>
										<th className="text-center">Avg Distance</th>
										<th className="text-center">Avg Pace</th>
										<th className="text-center">Avg Avg HR</th>
										<th className="text-center">Avg Max HR</th>
										<th className="text-center">Avg Temp</th>
									</tr>
									</thead>
									<tbody>	 	
									{summary.map((sdata) => (
										<tr>
											<td>{sdata.print_duration}</td>
											<td>{sdata.avg_distance}</td>
											<td>{sdata.print_pace}</td>
											<td>{sdata.avg_heartRateAvg}</td>
											<td>{sdata.avg_heartRateMax}</td>
											<td>{sdata.avg_temp}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>	
						</div>	
        		
        			<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="11">
	  							<h4>Runs in the Last {timeFrame} Days</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th>Date</th>
    						<th>Duration</th>
    						<th>Distance</th>
    						<th>Pace</th>
    						<th>Avg HR</th>
    						<th>Max HR</th>
        					<th>Temp</th>
        					<th>Wind Speed</th>
        					<th>Wind Direction</th>
        					<th>Weather Conditions</th>
        					<th>Location</th>
        				</tr>
        				</thead>
        				<tbody>	 	
          				{runs.map((runs) => (
          				<tr>
          					<td>
								<Link to={"/smashrunspecificone?runid="+runs.runId} activeClassName="active">
									<Moment format="MM/DD/YYYY @ hh:mm a">{runs.print_date}</Moment>
								</Link>	
							</td>
            				<td>{runs.print_duration}</td>
            				<td span style ={{color: runs.changeColorDistance, backgroundColor: 'white'}} >
            					{runs.distance}
            				</td>
            				<td>{runs.print_pace}</td>
            				<td>{runs.heartRateAvg}</td>
            				<td>{runs.heartRateMax}</td>
            				<td>{runs.temp}</td>
            				<td>{runs.windspeed}</td>
            				<td>{runs.winddir}</td>
            				<td>{runs.weathcond}</td>
            				<td className="text-left">{runs.location}</td>
        				</tr>
          				))}
          				</tbody>
          			</table>
          			<h5>Distance in <span className="text-danger">red </span>indicates longest distance in the past {timeFrame} days.</h5>
        		</div>
       		</div>
       	</div>
    </div>
    );
}

export default Smashrunrunslist;


