import React, { useEffect,useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";

function Bestraces() {

const[runs, setRuns] = useState([]);

	//fetch('https://stats.firehole.us/api/smashruntolocal')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		accessToken = JSON.parse(localStorage['token']);
		
		useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'smashrun_bestraces',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setRuns( data ))
     	},[])        

    return (
    	<div id="outer-container">
       		<Helmet>
					<title>Firehole - Runs</title>
			</Helmet>
       		<div id = "page-wrap">
       			<div id="logo" className="sitelogo"><img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       			<div className="container">
       				<div className="col-lg-12 col-xs-12 col-md-8">
        				<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="6">
	  							<h4>Best Races</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th>Stat</th>
    						<th>Date</th>
    						<th>Location</th>
    						<th>Time</th>
    						<th>Pace</th>
    						<th>Temperature</th>
        				</tr>
        				</thead>
        				<tbody>	 	
          					{runs.map((runs) => (
          					<tr>
          						<td className={runs.BoldtheLabel ? 'bold' : 'regular'}>
          						{runs.label}
          						</td>
								<td className={runs.BoldtheLabel ? 'bold' : 'regular'}>
								<Moment format="MM/DD/YYYY @ hh:mm a">{runs.print_date}</Moment>
								</td>	
								<td className={runs.BoldtheLabel ? 'bold text-left'  : 'regular text-left' }>
								{runs.location}
								</td>
								<td className={runs.BoldtheLabel ? 'bold' : 'regular'}>
								{runs.print_duration}
								</td>
								<td className={runs.BoldtheLabel ? 'bold' : 'regular'}>
								{runs.print_pace}
								</td>
								<td className={runs.BoldtheLabel ? 'bold' : 'regular'}>
								{runs.temp}
								</td>
							</tr>          	
          					))}
          				</tbody>
          				</table>
        			</div>
       			</div>
       		</div>
    	</div>
    );
}
   
export default Bestraces;


