import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';

function Whoopactivity() {

		const[activity, setActivity] = useState([]);
		const[summary, setSummary] = useState([]);	
	
    	
    	const App_Key = '!4uYm7D7Q2Yx';
    	var accessToken = "";
    	
    	const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
    	//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getActivitiesData(timeFrame);
    		getSummaryData(timeFrame);
				
        },[timeFrame])  
        
        const getActivitiesData = (timeFrame) => {
        	fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'whoop_activities',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setActivity( data ))
        };
        
         const getSummaryData = (timeFrame) => {
        	fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'whoop_summary',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setSummary( data ))
        };
        
       
    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Activity</title>
			</Helmet>
       		<div id = "page-wrap">
       		
       		<div id="logo" className="sitelogo"><img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		<div className="container">
       		        	
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
       		
       				<div className="container-fluid">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex align-items-center justify-content-center">
								<table className="table table-bordered table-striped table-sm">
									<thead className="thead-inverse">
									<tr>
										<td className="text-center" colSpan="7">
												<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
												<h4>Activity Overview - Last {timeFrame} Days</h4>
											
										</td>
									</tr>	
									<tr>
										<th className="text-center">Number of Activities</th>
										<th className="text-center">Most Frequent Activity</th>
										<th className="text-center">Most Frequent Activity Count</th>
										<th className="text-center">All Activities, Avg Elapsed Time</th>
										<th className="text-center">All Activities, Avg Strain</th>
										<th className="text-center">All Activities, Avg Avg HR</th>
										<th className="text-center">All Activities, Avg Max HR</th>
									</tr>
									</thead>
									<tbody>	 	
									{summary.map((sdata) => (
										<tr>
											<td>{sdata.total_activity_count}</td>
											<td>{sdata.most_activity_name}</td>
											<td>{sdata.most_activity_count}</td>
											<td>{sdata.average_elapsed_time}</td>
											<td>{sdata.average_strain}</td>
											<td>{sdata.average_average_heartrate}</td>
											<td>{sdata.average_max_heartrate}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>	
						</div>	
       		
        			<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td colSpan="7">
	  							<h4>Activities in the Last {timeFrame} Days</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th className="text-center">Activity Start</th>
     						<th className="text-center">Activity End</th>
     						<th className="text-center">Elapsed Time</th>
    						<th className="text-center">Activity Type</th>
    						
    						<th className="text-center">Strain</th>
    						<th className="text-center">Avg HR</th>
    						<th className="text-center">Max HR</th>
        				</tr>
        				</thead>
        				<tbody>	 	
          				{activity.map((activities) => (
          				<tr>
          					<td>
          						<Moment format="MM/DD/YYYY @ hh:mm a">{activities.start}</Moment>
							</td>
							<td>
          						<Moment format="MM/DD/YYYY @ hh:mm a">{activities.end}</Moment>
							</td>
							<td span style ={{color: activities.changeColorElapsedTime, backgroundColor: 'white'}} >
          						{activities.elapsed_time}
							</td>
            				<td>{activities.activity_name}</td>
            			
            				<td span style ={{color: activities.changeColorStrain, backgroundColor: 'white'}} >
            					{activities.rounded_strain}
            				</td>
            				<td span style ={{color: activities.changeColorAvg, backgroundColor: 'white'}} >
            					{activities.average_heart_rate}
            				</td>
            				
            				<td span style ={{color: activities.changeColorMax, backgroundColor: 'white'}} >
            					{activities.max_heart_rate}
            				</td>
            				
        				</tr>
          				))}
          				</tbody>
          			</table>
          			<h5>Statistics in <span className="text-danger">red </span>indicates highest statistics in the past {timeFrame} days.</h5>
        		</div>
       		</div>
       	</div>
    </div>
    );
}
export default Whoopactivity;


