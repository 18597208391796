import React from 'react';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaCalendar } from "react-icons/fa6"
import { FaPersonRunning } from "react-icons/fa6"
import { SiGooglefit } from "react-icons/si"
import { SiPeloton } from "react-icons/si"
import { FaApple } from "react-icons/fa6"
import { FaStrava } from "react-icons/fa6"

import { GiWeightScale } from "react-icons/gi";
import { BsSmartwatch } from "react-icons/bs";

import "../styles/styles.css";

function NavigationBar() {
const calendarTitle = (<div> <FaCalendar />  Calendars </div>);
const runsTitle = (<div> <FaPersonRunning />  Runs</div>);
const googleFitTitle = (<div> <SiGooglefit />  Google Fit</div>);
const pelotonTitle = (<div> <SiPeloton />  Peloton</div>);
const stravaTitle = (<div> <FaStrava />  Strava</div>);
const withingsTitle = (<div> <GiWeightScale />  Withings</div>);
const whoopTitle = (<div> <BsSmartwatch />  Whoop</div>);
const applehealthTitle = (<div> <FaApple />  Apple Health</div>);

	return (
		<Navbar bg="light" expand="lg" variant="light">
		
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Navbar.Brand href="https://health.firehole.us">
						&nbsp;
						<img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo" width="30" height="30" />
						&nbsp;Firehole Health&nbsp;&nbsp;
					</Navbar.Brand>
					<div class="vr opacity-100"></div>
					<LinkContainer to="/summarycalendar">
						<NavDropdown title={calendarTitle} id="basic-nav-dropdown">
							<LinkContainer to="/summarycalendar">
								<NavDropdown.Item>
									  Summary
								</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/activitycalendar">
								<NavDropdown.Item>
									Activity
								</NavDropdown.Item>
							</LinkContainer>	
						</NavDropdown>		
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/whoopactivity">
						<NavDropdown title={whoopTitle} id="basic-nav-dropdown">
							<LinkContainer to="/whoopactivity">
								<NavDropdown.Item>
									Activity
								</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/whoopsleep">
								<NavDropdown.Item>
									Sleep
								</NavDropdown.Item>
							</LinkContainer>	
							<LinkContainer to="/whooprecovery">
								<NavDropdown.Item>
									Recovery
								</NavDropdown.Item>
							</LinkContainer>	
						</NavDropdown>		
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/smashrunlist">
						<NavDropdown title={runsTitle} id="basic-nav-dropdown">
							<LinkContainer to="/smashrunrunslist">
								<NavDropdown.Item>
									Runs List
								</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/weeklymonthly">
								<NavDropdown.Item>
									Weekly, Monthly, This Day
								</NavDropdown.Item>
							</LinkContainer>	
							<LinkContainer to="/historical">
								<NavDropdown.Item>
									Historical
								</NavDropdown.Item>
							</LinkContainer>	
							<LinkContainer to="/bestraces">
								<NavDropdown.Item>
									Best Races
								</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to="/favoriteroutes">
								<NavDropdown.Item>
									Routes
								</NavDropdown.Item>
							</LinkContainer>
						</NavDropdown>		
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/googlefit">
						<Nav.Link>{googleFitTitle}</Nav.Link>
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/peloton">
						<Nav.Link>{pelotonTitle}</Nav.Link>
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/stravaactivity">
						<Nav.Link>{stravaTitle}</Nav.Link>
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/withings">
						<Nav.Link>{withingsTitle}</Nav.Link>
					</LinkContainer>
					<div class="vr"></div>
					<LinkContainer to="/applehealth">
						<Nav.Link>{applehealthTitle}</Nav.Link>
					</LinkContainer>
					<div class="vr opacity-100"></div>
					
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}
export default NavigationBar;