import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {Helmet} from "react-helmet";
import Stack from '@mui/material/Grid';
import Grid from '@mui/material/Grid';

import Button from 'react-bootstrap/Button';

import { LineChart, Line, CartesianGrid, XAxis, YAxis,Tooltip } from 'recharts';

import "../styles/styles.css";


function Pelotonspecificone() {

		
		const[peloton_specificone, setPeloton_specificone] = useState([]);

		const search = useLocation().search.slice(1);
		const { workoutid } = Object.fromEntries(new URLSearchParams(search));
	
		//fetch('https://services.firehole.us/api/whoop')
		
		const App_Key = '!4uYm7D7Q2Yx';
		var accessToken = "";
		
		//remove beginning and ending quotes
		
		//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		useEffect(() => {
			fetch('https://services.firehole.us/api/firehole_api/',{
		
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'peloton_specific_one',
				'WORKOUTID': `${workoutid}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
			})
		
			.then (res => res.json())
            .then(data => setPeloton_specificone( data ))
     	},[])
     	
	const navigate = useNavigate();
	const graph_width = 1000;
	const graph_height = 400;

    return (
    	
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Peloton</title>
			</Helmet>
       		<div id = "page-wrap">
				<div id="logo" className="sitelogo"><img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
					
					<div className="container-fluid">
						<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
							<table className="table table-bordered table-striped w-75 table-sm align-self-center align-items-center text-center">
								<thead className="thead-inverse">
								<tr>
									<td className="text-center" colSpan="7">
										
											<h4>Peloton Workout Details</h4>
										
									</td>
								</tr>	
								<tr>
									<th className="text-center">Workout Date</th>
									<th className="text-center">Workout Time</th>			
									<th className="text-center">Workout Name</th>
									<th className="text-center">Instructor Name</th>
									<th className="text-center">Leaderboard Rank</th>
									<th className="text-center">Averages</th>
									<th className="text-center">Maximums</th>
		
								</tr>
								</thead>
								<tbody>
									{peloton_specificone.map((srecord) => (
									<tr>
										<td>{srecord.start_date}</td>
										<td>{srecord.workout_time}</td>
										<td>{srecord.workout_name}</td>
										<td>{srecord.instructor_name}</td>
										<td>{srecord.leaderboard_rank}</td>
										<td>
											<Stack>
												<div className="d-flex justify-content-start">{srecord.avg_performance_label0}</div>
												<div className="d-flex justify-content-start">{srecord.avg_performance_label1}</div>
												<div className="d-flex justify-content-start">{srecord.avg_performance_label2}</div>
												<div className="d-flex justify-content-start">{srecord.avg_performance_label3}</div>
												<div className="d-flex justify-content-start">{srecord.avg_performance_label4}</div>

											</Stack>
										</td>
										<td>
											<Stack>
												<div className="d-flex justify-content-start">{srecord.max_performance_label0}</div>
												<div className="d-flex justify-content-start">{srecord.max_performance_label1}</div>
												<div className="d-flex justify-content-start">{srecord.max_performance_label2}</div>
												<div className="d-flex justify-content-start">{srecord.max_performance_label3}</div>
												<div className="d-flex justify-content-start">{srecord.max_performance_label4}</div>
											</Stack>
										</td>
									</tr>
								))}
								</tbody>
							</table>
						</div>		
						
						<div className="container-fluid" width="100%">
							{peloton_specificone.map((srecord) => (					
							<div>	
								<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">	
									<LineChart width={graph_width} height={graph_height} data={srecord.Output} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
										<XAxis dataKey="name" label={{value:"Minutes",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"Watts",position: 'insideLeft'}}/>
										<Tooltip />
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Output</tspan>
										</text>
									</LineChart>
								
									<LineChart width={graph_width} height={graph_height} data={srecord.Cadence} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
										<XAxis dataKey="name" label={{value:"Minutes",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"RPM",position: 'insideLeft'}}/>
										<Tooltip />
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Cadence</tspan>
										</text>
									</LineChart>
								</div>
								<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
									<LineChart width={graph_width} height={graph_height} data={srecord.Resistance} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
										<XAxis dataKey="name" label={{value:"Minutes",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"Resistance",position: 'insideLeft'}}/>
										<Tooltip />
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Resistance</tspan>
										</text>
									</LineChart>
								
									<LineChart width={graph_width} height={graph_height} data={srecord.Speed} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
										<XAxis dataKey="name" label={{value:"Minutes",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"MPH",position: 'insideLeft'}}/>
										<Tooltip />
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Speed</tspan>
										</text>
									</LineChart>
								</div>	
								<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8 d-flex justify-content-center">
									<LineChart width={graph_width} height={graph_height} data={srecord.Heart_Rate} margin={{ top: 5, right: 5, bottom: 10, left: 0 }}>
										<Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
										<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
										<XAxis dataKey="name" label={{value:"Minutes",position: "insideBottom",offset:-5}}/>
										<YAxis dataKey={"value"} label={{angle:270,value:"BPM",position: 'insideLeft'}}/>
										<Tooltip />
										<text x={graph_width / 2} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
											<tspan fontSize="16" font-weight="bold">Heart Rate</tspan>
										</text>
									</LineChart>
								</div>											
							</div>
							))}	
									
						</div>
					
						<Grid item xs={12} sm={12} md={4} lg={4}
   							style={{
       							textAlign:'center' // this does the magic
   							}}
							>
						<Button variant="primary" onClick={() => navigate(-1)}>Back</Button>
						</Grid>
						
					</div>	
					
				</div>
       		</div>

	);
}

export default Pelotonspecificone;