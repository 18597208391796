import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Helmet} from "react-helmet";
import TimeFrameDropDown2 from "./dropdown2";

import 'moment-timezone';


function Stravaactivity() {
const[activity, setActivity] = useState([]);	
  	 	console.log ("in Firehole Health App version 0.11 -- Stravaactivity");
    	
    	const App_Key = '!4uYm7D7Q2Yx';
    	var accessToken = "";
    	
    	const selectOptions = [
    		{	key: '30',
    			value: '30 days',
    		},	
    		{	key: '60',
    			value: '60 days',
    		},
    		{	key: '90',
    			value: '90 days',
    		},
    		{	key: '120',
    			value: '120 days',
    		},
    		{	key: '150',
    			value: '150 days',
    		},
    		{	key: '180',
    			value: '180 days',
    		},
    	]
    	
    	//if (localStorage['token'].at(0) === '"' && localStorage['token'].at(-1) === '"') {
 		//	 accessToken = localStorage['token'].slice(1, -1);
		//}
		
		accessToken = JSON.parse(localStorage['token']);
		
		const [timeFrame,setTimeFrame] = useState(30);
				
    	useEffect(() => {
    		getStravaData(timeFrame);
				
        },[timeFrame])  
        
        const getStravaData = (timeFrame) => {
        		fetch('https://services.firehole.us/api/strava/')
				fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'strava_activities',
				'TIMEFRAME':`${timeFrame}`,
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setActivity( data ))
        };                

    return (
       <div id="outer-container">
       		<Helmet>
					<title>Firehole - Activity</title>
			</Helmet>
       		<div id = "page-wrap">
       		<div id="logo" className="sitelogo"><img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
       		<div className="container-fluid">
        		<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
        			<table className="table table-bordered table-striped table-sm">
	  					<thead className="thead-inverse">
	  					<tr>
	  						<td className="text-center" colSpan="13">
	  							<span id="dropdown" class="text-start"><TimeFrameDropDown2 setTimeFrame={setTimeFrame} selectOptions={selectOptions}/></span>
	  							<h4>Strava Activities in the Last {timeFrame} Days</h4>
	  						</td>
	  					</tr>	
	  					<tr>
     						<th className="text-center">Activity Start</th>
     						<th className="text-center">Elapsed Time</th>
     						<th className="text-center">Activity Name</th>
    						<th className="text-center">Activity Type</th>
    						<th className="text-center">Distance</th>
							<th className="text-center">Moving Minutes</th>
							<th className="tet-center">Suffer Score</th>
							<th className="text-center">Avg Speed</th>
							<th className="text-center">Max Speed</th>
							<th className="text-center">Avg Cadence</th>
    						<th className="text-center">Avg HR</th>
    						<th className="text-center">Max HR</th>
    						<th className="text-center">Description</th>
        				</tr>
        				</thead>
        				<tbody>	 	
          				{activity.map((activities) => (
          				<tr>
          					<td>
          						<Moment format="MM/DD/YYYY @ hh:mm a">{activities.start_date_local}</Moment>
							</td>
							<td span style ={{color: activities.changeColorElapsedTime, backgroundColor: 'white'}} >
          						{activities.moving_time_minutes}
							</td>
            				<td>{activities.strava_name}</td>
            				<td>{activities.type}</td>
            				<td>{activities.distance_in_miles}</td>
            				<td>{activities.moving_time_minutes}</td>
            				<td span style ={{color: activities.changeColorSufferScore, backgroundColor: 'white'}} >
            					{activities.suffer_score}
            				</td>
            				<td>{activities.average_speed_mph}</td>
            				<td>{activities.max_speed_mph}</td>
            				<td>{activities.average_cadence}</td>
            				<td span style ={{color: activities.changeColorAvg, backgroundColor: 'white'}} >
            					{activities.average_heartrate}
            				</td>
            				
            				<td span style ={{color: activities.changeColorMax, backgroundColor: 'white'}} >
            					{activities.max_heartrate}
            				</td>
            				<td>{activities.description}</td>
        				</tr>
          				))}
          				</tbody>
          			</table>
          			<h5>Statistics in <span className="text-danger">red </span>indicates highest statistics in the past {timeFrame} days.</h5>
        		</div>
       		</div>
       	</div>
    </div>
    );
}
export default Stravaactivity;


