import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {Helmet} from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IconContext } from "react-icons";
import { FaCircleCheck } from "react-icons/fa6"
import { FaCircleExclamation } from "react-icons/fa6"
import { FaCircleXmark } from "react-icons/fa6"

import "../styles/styles.css";
import '../styles/index.css';
import "react-big-calendar/lib/css/react-big-calendar.css";


//<div className={`modal-${modalState == true ? 'show' : 'hide'}`}>
//titleAccessor = {calendar.title} <IconContext.Provider value={{ className: "top-react-icons" }}><FaCircleCheck /></IconContext.Provider>
//titleAccessor={(calendar) => {
//		const returnIcon = <FaCircleCheck /> 									
// 		return returnIcon
//}}

const localizer = momentLocalizer(moment);

function Summarycalendar() {
		const [calendar, setCalendar] = useState([]);
		const [selectedEvent, setSelectedEvent] = useState(undefined)
   		const [show, setShow] = useState(false);
   		const handleClose = () => setShow(false);
   		    		    	
    	const App_Key = '!4uYm7D7Q2Yx';
    	var accessToken = "";
 	 
 	 	const handleSelectedEvent = (event) => {
      		setSelectedEvent(event)
      		setShow(true)
   		}
 	 
 	 	const ModalWindow = () => {
/* switch = 0, Recovery, =1, Sleep, =2, Strain, =3, Vitals */
			var iconType = "";
			switch(selectedEvent.switch) {
				case 0:
					var recoveryIcon = "";
					var recoveryIconExplain = "";
					switch (selectedEvent.resource.backgroundColor) {
						
						case "green":
							iconType =  <FaCircleCheck />
							recoveryIconExplain = <small>( Recovery {">="} 67% )</small>
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							recoveryIconExplain = <small>( Recovery {">"} 33% {"<"} 67% )</small>			
							break;	
						case "red":
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( Recovery {"<="} 33% )</small>			
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in CASE 0 )</small>	
							break;
					}		
					recoveryIcon = ( <IconContext.Provider value={{ color: selectedEvent.resource.backgroundColor }}>
									{iconType}
								</IconContext.Provider>
								);
					return(
						<>
						<Modal show={show} onHide={handleClose} animation={false}>
							<Modal.Header closeButton>
								<Modal.Title>{selectedEvent.title}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<small>
									<div className="d-flex align-items-center">
										{recoveryIcon}&nbsp;
										{selectedEvent.title}&nbsp;&nbsp;
										{recoveryIconExplain}
									</div>
									<hr class="hr" />	
									<div className="d-flex align-items-center">								
										30 day AVG Recovery - {selectedEvent.resource.avgRecovery}%										
									</div>	
									<div className="d-flex align-items-center">
										30 day MIN Recovery - {selectedEvent.resource.minRecovery}%
									</div>
									<div className="d-flex align-items-center">
										30 day MAX Recovery - {selectedEvent.resource.maxRecovery}%
									</div>	
								</small>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
      					</Modal>
    					</>					
					);
					
				case 1:
					var sleepIcon = "";
					var sleepIconExplain = "";
					switch (selectedEvent.resource.backgroundColor) {
						
						case "green":
							iconType =  <FaCircleCheck />
							sleepIconExplain = <small>( Sleep Performance {">="} 80% )</small>
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							sleepIconExplain = <small>( Sleep Performance {">"} 50% {"<"} 80% )</small>			
							break;	
						case "red":
							iconType =  <FaCircleXmark />
							sleepIconExplain = <small>( Sleep Performance {"<="} 50% )</small>			
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in CASE 1 )</small>	
							break;	
					}		
					sleepIcon = ( <IconContext.Provider value={{ color: selectedEvent.resource.backgroundColor }}>
									{iconType}
								</IconContext.Provider>
								);
					return(
						<>
						<Modal show={show} onHide={handleClose} animation={false}>
							<Modal.Header closeButton>
								<Modal.Title>{selectedEvent.title}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<small>
									<div className="d-flex align-items-center">
										{sleepIcon}&nbsp;
										{selectedEvent.title}&nbsp;&nbsp;
										{sleepIconExplain}
									</div>
									<div className="d-flex align-items-center">
										In bed time: {selectedEvent.resource.totalInBedTimeHours}:{selectedEvent.resource.totalInBedTimeMinutes}
									</div>
									<div className="d-flex align-items-center">
										Awake time: {selectedEvent.resource.totalAwakeTimeHours}:{selectedEvent.resource.totalAwakeTimeMinutes}
									</div>
									<div className="d-flex align-items-center">
										Sleep time: {selectedEvent.resource.totalSleepTimeHours}:{selectedEvent.resource.totalSleepTimeMinutes}
									</div>
									<div className="d-flex align-items-center">
										Number of disturbances: {selectedEvent.resource.disturbanceCount}
									</div>
									<hr class="hr" />	
									
									<div className="d-flex align-items-center">								
										30 day AVG Number of Disturbances - {selectedEvent.resource.avgDisturbanceCount}										
									</div>	
									<div className="d-flex align-items-center">								
										30 day AVG Sleep Performance - {selectedEvent.resource.avgSleep}%										
									</div>	
									<div className="d-flex align-items-center">
										30 day MIN Sleep Performance - {selectedEvent.resource.minSleep}%
									</div>
									<div className="d-flex align-items-center">
										30 day MAX Sleep Performance - {selectedEvent.resource.maxSleep}%
									</div>	
								</small>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
      					</Modal>
    					</>					
					);
					
				case 2:
					var strainIcon = "";
					var strainIconExplain = "";
					switch (selectedEvent.resource.backgroundColor) {
						
						case "green":
							iconType =  <FaCircleCheck />
							strainIconExplain = <small>( Strain {">="} 14 )</small>
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							strainIconExplain = <small>( Strain {">"} 9 {"<"} 14 )</small>			
							break;	
						case "red":
							iconType =  <FaCircleXmark />
							strainIconExplain = <small>( Strain {"<="} 9 )</small>			
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in CASE 2 )</small>	
							break;
					}		
					strainIcon = ( <IconContext.Provider value={{ color: selectedEvent.resource.backgroundColor }}>
									{iconType}
								</IconContext.Provider>
								);
					return(
						<>
						<Modal show={show} onHide={handleClose} animation={false}>
							<Modal.Header closeButton>
								<Modal.Title>{selectedEvent.title}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<small>
									<div className="d-flex align-items-center">
										{strainIcon}&nbsp;
										{selectedEvent.title}&nbsp;&nbsp;
										{strainIconExplain}
									</div>
									<hr class="hr" />	
									<div className="d-flex align-items-center">								
										30 day AVG Strain - {selectedEvent.resource.avgStrain}										
									</div>	
									<div className="d-flex align-items-center">
										30 day MIN Strain - {selectedEvent.resource.minStrain}
									</div>
									<div className="d-flex align-items-center">
										30 day MAX Strain - {selectedEvent.resource.maxStrain}
									</div>	
								</small>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
      					</Modal>
    					</>					
					);
					
				case 3:
					var hrvIconExplain = ""; 
					var rhrIconExplain = ""; 
					var rrIconExplain = ""; 
					var o2IconExplain = "";
					var hrvIcon = "";
					var rrIcon = "";
					var rhrIcon = "";
					var o2Icon = "";
					switch (selectedEvent.resource.hrv_backgroundColor) {
						
						case "green":
							iconType =  <FaCircleCheck />
							hrvIconExplain = <small>( HRV > 23 )</small>
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							hrvIconExplain = <small>( HRV {">="} 14 {"<="} 23 )</small>			
							break;	
						case "red":
							iconType =  <FaCircleXmark />
							hrvIconExplain = <small>( HRV {"<"} 14 )</small>			
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in CASE 3 )</small>	
							break;	
					}		
					hrvIcon = ( <IconContext.Provider value={{ color: selectedEvent.resource.hrv_backgroundColor }}>
									{iconType}
								</IconContext.Provider>
								);
					switch (selectedEvent.resource.rr_backgroundColor) {
						case "green":
							iconType =  <FaCircleCheck />
							rrIconExplain = <small>( RR {"<"} 16.5 )</small>
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							rrIconExplain = <small>( RR {">="} 16.5 {"<="} 17 )</small>
							break;	
						case "red":
							iconType =  <FaCircleXmark />
							rrIconExplain = <small>( RR > 17 )</small>				
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in rr )</small>	
							break;
						                      
					}		
					rrIcon = ( <IconContext.Provider value={{ color: selectedEvent.resource.rr_backgroundColor }}>
									{iconType}
								</IconContext.Provider>
								);
					switch (selectedEvent.resource.rhr_backgroundColor) {
						case "green":
							iconType =  <FaCircleCheck />
							rhrIconExplain = <small>( RHR {"<"} 71 )</small>
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							rhrIconExplain = <small>( RHR {">="} 71 {"<="} 78 )</small>
							break;	
						case "red":
							iconType =  <FaCircleXmark />
							rhrIconExplain = <small>( RHR > 78 )</small>				
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in CASE rhr )</small>	
							break;	
						                      
					}		
					rhrIcon = ( <IconContext.Provider value={{ color: selectedEvent.resource.rhr_backgroundColor }}>
									{iconType} 
								</IconContext.Provider>
								);			
					switch (selectedEvent.resource.o2_backgroundColor) {
						
						case "green":
							iconType =  <FaCircleCheck />
							o2IconExplain = <small>( O2% > 90 )</small>		
							break;		
						case "orange":
							iconType =  <FaCircleExclamation />
							o2IconExplain = <small>( O2% {"<"} 89 )</small>			
							break;	
						case "red":
							iconType =  <FaCircleXmark />				
							break;
						default:
							iconType =  <FaCircleXmark />
							recoveryIconExplain = <small>( UNDEFINED case for color in CASE o2 )</small>	
							break;	
					}		
					o2Icon = ( <IconContext.Provider value={{ color: selectedEvent.resource.o2_backgroundColor }}>
									{iconType}
								</IconContext.Provider>
								);						
					return(
						<>
						<Modal show={show} onHide={handleClose} animation={false}>
							<Modal.Header closeButton>
								<Modal.Title>{selectedEvent.title}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<small>
									<div className="d-flex align-items-center">
										{hrvIcon}&nbsp;
										Heartrate Variability - {selectedEvent.resource.hrv}&nbsp;&nbsp;
										{hrvIconExplain}
									</div>	
									<div className="d-flex align-items-center">
										{rrIcon}&nbsp;
										Respiratory Rate - {selectedEvent.resource.respiratory_rate}&nbsp;&nbsp;
										{rrIconExplain}
									</div>
										{rhrIcon}&nbsp;
										Resting Heart Rate - {selectedEvent.resource.resting_heart_rate}&nbsp;&nbsp;
										{rhrIconExplain}
									<div className="d-flex align-items-center">
										{o2Icon}&nbsp;
										O2 Percentage - {selectedEvent.resource.o2_percentage}%&nbsp;&nbsp;
										{o2IconExplain}
									</div>
								</small>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
      					</Modal>
    					</>					
					);
					
				default:
					console.log("UNDEFINED case for initial switch");
					break;
   				}						
		}
 	 
 	 	accessToken = JSON.parse(localStorage['token']);
		
    	useEffect(() => {
				fetch('https://services.firehole.us/api/firehole_api/',{
				headers: {
				'APP-KEY':App_Key,
				'API-NAME':'summary_calendar',
				'Content-type': 'application/json',
        		'Authorization': `Bearer ${accessToken}`, 
				},
				})
				.then (res => res.json())
        		.then((data) => setCalendar( data ))
        },[])
        for (let i = 0; i < calendar.length; i++ ) {
        	calendar[i].recovery_created_at_date = moment(calendar[i].recovery_created_at_date).toDate();
        }	
        const event = ({ event }) => {
        		
        			switch(event.resource.backgroundColor) {
        				case "green":
							return(
								<div className="d-flex justify-content-start align-items-center">
									<IconContext.Provider value={{ color: "green" }}>
										<FaCircleCheck />
									</IconContext.Provider>      
									&nbsp;
									<span className="text-black">{event.title}</span>
        						</div>  					
   							);
   							
        				case "orange":
        					return(
								<div className="d-flex justify-content-start align-items-center">
									<IconContext.Provider value={{ color: "orange" }}>
										<FaCircleExclamation />
									</IconContext.Provider>	  
									&nbsp;
									<span className="text-black">{event.title}</span>
        						</div>
        					);
        					
        				case "red":
        					return(
								<div className="d-flex justify-content-start align-items-center">
									<IconContext.Provider value={{ color: "red" }}>
										<FaCircleXmark />
									</IconContext.Provider>
									&nbsp;
									<span className="text-black">{event.title}</span>
        						</div>
        					);
        					
        				default:
        					console.log("event resource backgroundColor NOT green, orange, or red");
        					break;	
        			}	
        }
        
		return(
			<div id = "outer-container">
				<div id = "page-wrap">
					<div id="logo" className="sitelogo"><img src="https://health.firehole.us/icons/firehole.jpg" alt="firehole logo"></img></div>
						<div className="container-fluid w-75">
							<div className="col-lg-12 col-md-8 col-sn-6 col-lg-8">
								<Helmet>
									<title>Firehole - Summary Calendar</title>
								</Helmet>
								{selectedEvent && <ModalWindow />}
								<Calendar 
									eventPropGetter={(calendar) => {
										const backgroundColor = "white"; 
										//const backgroundColor = calendar.resource.backgroundColor;
										const fontSize= '15px';
										
										return { style: { backgroundColor ,fontSize } }
									}}
									
									//titleAccessor = {function(calendar){console.log(calendar);return calendar.title;}}
									localizer={localizer} 
									startAccessor="end" 
									endAccessor="end" 
									showAllEvents="true" 
									selectable
									views={{week:true, day: true, month: true}} 
									events={calendar} 
									style={{ height: '70vh'}}
									components={{event: event}}
									onSelectEvent={(e) => handleSelectedEvent(e)}
								/>
							</div>
						</div>	
					</div>
				</div>
		
		);
	}

export default Summarycalendar;